import styled from 'styled-components';
import { deviceLessThan } from '../../styles/breakpoints';

export const MediaKitContainer = styled.div`
    width:75%;
    max-width: 1200px;
    margin:0 auto;
    &::after{
        content: "";
        background:url(${props => props.backgroundAfter}) no-repeat;
        background-size: 66px 300px;
        position: absolute;
        bottom: -150px;
        right: 0px;
        height: 300px;
        width: 66px;
        z-index: -1;
    };
    &::before {
        content: "";
        background:url(${props => props.backgroundBefore}) no-repeat;
        background-size: 55px 300px;
        position: absolute;
        top: 0px;
        right: 13%;
        height: 300px;
        width: 145px;
        z-index: -1;
        transform:rotate(90deg);
    }
    @media ${deviceLessThan.laptop}{
        width:95%;
    };
    @media ${deviceLessThan.tablet}{
        &::before {
            content: "";
            background:unset;
            width: 219px;
            border-radius: 50%;
            height: 219px;
            background-color: #eef8fd;
            position: absolute;
            top: 0px;
            left: -70px;
            z-index:-1;
        }
        &::after{
            content: "";
            background:unset;
            background-size: 66px 300px;
            position: absolute;
            bottom: -150px;
            right: 0px;
            height: 300px;
            width: 66px;
            z-index: -1;
        };
    }
`;

export const MediaCenterPageLink = styled.div`
    color:${props => props.color ? props.theme.colors[props.color] : props.theme.colors.CHQBOOK_BLUE};
    font-size:16px;
    padding:5px 15px;
    border:1.5px solid #f7f7f7;
    cursor:pointer;
    width:100%;
    text-align:center;
    & a{
        color:#19447a;
    }
`

export const NewsCardContainer = styled.div`
    border-radius: 6px;
    box-shadow: 0 0 13px 1px rgba(0,0,0,0.1);
    padding: 16px;
    text-align:${props => props.align};
    background: white;
    height: 100%;
    padding:15px 15px;
    @media ${deviceLessThan.tablet}{
        text-align:unset;
        box-shadow:0 0 16px 0 rgba(0, 0, 0, 0.1);
        padding: 10px;
    }
`;

export const ViewImageHeader = styled.div`
    display:flex;
    background-color:#2b2b2b;
    width:100%;
    color:white;
    padding:10px 55px;
    font-weight: 600;
    @media ${deviceLessThan.tablet}{
        padding:10px 23px;
    }
`;

export const ViewImageBody = styled.div`
    background-color:#444;
    padding:25px 55px;
    @media ${deviceLessThan.tablet}{
        padding:23px;
    }
`;

export const ViewImageTitle = styled.div`
    flex:1;
    font-size:16px;
    padding:6px 0px
`;

export const OverlayContainer = styled.div`
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
`;

export const MediaKitDocContainer = styled.div`
    display:flex;
    justify-content: space-between;
    padding: 18px 30px 18px 0px;
    border-radius: 6px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    @media ${deviceLessThan.tablet}{
        flex-direction:column;
        padding:24px 20px;
        margin:34px auto;
    }
`

export const MediaKitDocWrapper = styled.div`
    flex: 1;
    border-right: 1px solid #dadada;
    padding:0px 0px 0px 30px;
    cursor:pointer;
    &:last-of-type{
        border-right:none;
    }
    @media ${deviceLessThan.tablet}{
        display:flex;
        text-align:left;
        border-bottom: 1px solid #dadada;
        border-right: unset;
        padding:15px 0px;
        &:last-of-type{
            border-bottom:none;
            padding:15px 0px 0px
        }
        & > div:first-of-type{
            width:34px;
        }
        &:first-of-type{
            padding:0px 0px 15px
        }
        & div:nth-of-type(2){
            margin:-5px 0px 0px 13px;
        }
    }
`;

export const MediaKitTop = styled.div`
    margin: 85px auto 52px;
    position:relative;
    &::before{
        content: "";
        background:url(${props => props.backgroundBefore}) no-repeat;
        background-size: 108px 108px;
        position: absolute;
        top: 210px;
        right: -80px;
        height: 108px;
        width: 108px;
        z-index: -1;
    };
    @media ${deviceLessThan.laptop}{
        text-align:left;
        margin: 50px 0px 52px;
        &::before{
            top: 100px;
            right: 0px;
        };
    }
    @media ${deviceLessThan.tablet}{
        text-align:center;
        margin: 25px auto 0px;
        &::after{
            content: "";
            background:url(${props => props.backgroundAfter}) no-repeat;
            background-size: 280px 280px;
            position: absolute;
            bottom: 0px;
            left: -116px;
            height: 280px;
            width: 280px;
            z-index: -1;
            transform: rotate(60deg);
        };
    }
`;

export const MediaKitBody = styled.div`
    border-top:2px solid #dadada;
    &::after{
        content: "";
        background:url(${props => props.backgroundAfter}) no-repeat;
        background-size: 230px 280px;
        position: absolute;
        bottom: -460px;
        left: -35px;
        height: 280px;
        width: 230px;
        z-index: -1;
    };
    @media ${deviceLessThan.tablet}{
        margin: 25px auto;
        padding:30px 0px 45px;
        &::after{
            content: "";
            background:unset;
            background-size: 230px 280px;
            position: absolute;
            bottom: -460px;
            left: -35px;
            height: 280px;
            width: 230px;
            z-index: -1;
        };
    }
`;

export const DownloadWrapper = styled.div`
    display:flex;
    margin:0px 5px;
    @media ${deviceLessThan.tablet}{
        margin:8px 0px;
    }
`

export const GalleryCard = styled.div`
    box-shadow:${props => props.boxShadow};
    background:white;
    padding:10px;
    height: 100%;
    display:flex;
    flex-direction:column;
`

export const GalleryContainer = styled.div`
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding:0px 0px 50px;
    @media ${deviceLessThan.tablet}{
        padding:30px 0px;
        display: block;
    }
`;

export const GalleryFilter = styled.div`
    width:max-content;
    @media ${deviceLessThan.tablet}{
        margin: 0 auto;
    }
`

export const GalleryWrapper = styled.div`
    flex: 0 33.33%;
    box-sizing:border-box;
    width: 33.33%;
    position:relative;
    padding:0px 26px 26px 0px;
    @media ${deviceLessThan.tablet}{
        padding:${props => props.sPadding};
        text-align:left;
        margin:0 auto;
        width: 100%;
        padding:0px 0px 20px !important;
        &:last-child{
            padding:0px 0px 40px !important;
        }
    }
`;

export const ReachUsContainer = styled.div`
    background:url(${props => props.backgroundImage}) no-repeat #eef4fa;
    text-align: center;
    padding: 28px 0px;
    margin: 30px 0px 88px;
    border-radius: 8px;
    line-height: 1.7;
    @media ${deviceLessThan.tablet}{
        width:110%;
        margin: 0px -5%;
    }
`;
