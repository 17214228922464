import React, { Fragment, useState, useEffect } from 'react';
import Layout from '../common/Layout';
import Modal from '../common/Modal/index';
import { ModalContainer, ModalWrapper } from '../common/Modal/Component';
import MetaData from '../common/meta/MetaData';
import { downloadImage, onButtonClick } from '../../utils/helpers';
import {
    dotPatternHero,
    downArrow,
    downloadIcon,
    hexagonFill,
    hexagonNoFill,
    redUnderLine,
    boxPattern
} from '../../constants/strings';
import {
    MediaKitContainer,
    MediaKitTop,
    MediaKitDocContainer,
    MediaKitDocWrapper,
    MediaKitBody,
    MediaCenterPageLink,
    GalleryContainer,
    GalleryWrapper,
    GalleryCard,
    DownloadWrapper,
    GalleryFilter,
    ReachUsContainer
} from './Component';
import Heading from '../common/ui/Heading';
import CutomButton from '../common/ui/CutomButton'
import Text from '../common/ui/Text';
import { ImageContainer, Image } from '../common/ui/Image';
import CustomLink from '../common/ui/CustomLink';

const MediaKit = ({ title, description, keywords, location, newsList, data }) => {
    const [ toggleModal, setToggleModal ] = useState(false);
    const [ top, setTop ] = useState(null);
    const [ left, setLeft ] = useState(null);

    useEffect(() => {
        if (toggleModal) {
            document.body.style.overflow = 'hidden';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [toggleModal]);

    const modalHandler = (name, e) => {
        e.stopPropagation();
        if(e.target.getAttribute("class") && e.target.getAttribute("class").split(" ").includes("Back") === false){
            setToggleModal(!toggleModal);
            if(toggleModal == false){
                let element = document.getElementById(name).getBoundingClientRect();
                setTop(element.top+'px');
                setLeft(element.left+'px');
            }
        }
    }

    return (
    <Fragment>
        <MetaData
            data={{}}
            title={title}
            description={description}
            keywords={keywords}
            location={location}
            type="website"
        />
        <Layout>
            <MediaKitContainer
                backgroundAfter={dotPatternHero}
                backgroundBefore={dotPatternHero}
            >
                <MediaKitTop
                    backgroundBefore={hexagonFill}
                    backgroundAfter={hexagonNoFill}
                >
                    <CutomButton
                        minwidth="max-content"
                        swidth="116px"
                        width="177px"
                        backgroundcolor="WHITE"
                        color="CHQBOOK_BLUE"
                        weight="bold"
                        opacity="0.9"
                        size="xxl"
                        border="1px solid rgba(20, 70, 125, 0.2)"
                        hovercolor="primary"
                        boxshadow="0 4px 8px 0 rgba(0, 0, 0, 0.05)"
                        hoverbackground="WHITE"
                        smargin="0 auto"
                        padding="2px 12px 1px"
                        letterspacing="0.2px"
                        borderradius="6px"
                        onClick={modalHandler.bind(null, "newModal")}
                        id="newModal"
                    >
                        {data.sectionName}&nbsp;
                    <Image
                        src={downArrow}
                        width="12px"
                    />
                    </CutomButton>
                    <Modal
                        show={toggleModal}
                        modalClosed={modalHandler.bind(null, null)}
                    >
                        <ModalContainer
                            top={top}
                            left={left}
                            sTop="111px"
                            sLeft="50%"
                            sTransform="translate(-50%, 0%)"
                            transform="translate(0px, 0px)"
                            className="Back"
                        >
                             <CutomButton
                                    width="max-content"
                                    backgroundcolor="WHITE"
                                    color="CHQBOOK_BLUE"
                                    size="xxl"
                                    weight="bold"
                                    hoverbackground="WHITE"
                                    border="none"
                                    hovercolor="primary"
                                    onClick={modalHandler.bind(null, null)}
                                    padding="2px 10px"
                                    height="auto"
                                >
                                    {data.sectionName}&nbsp;&nbsp;
                                    <Image
                                        src={downArrow}
                                        width="12px"
                                        style={{ transform:"rotate(180deg)" }}
                                    />
                                </CutomButton>
                            <ModalWrapper className="Back">
                                {
                                    data.modalLink.map((el, i)=>(
                                            <CustomLink to={el.link} key={i}>
                                                <MediaCenterPageLink color="CHQBOOK_BLUE">
                                                    {el.text}
                                                </MediaCenterPageLink>
                                            </CustomLink>
                                        )
                                    )
                                }
                            </ModalWrapper>
                        </ModalContainer>
                    </Modal>
                    <Heading
                        color='MEDIUM_BLACK'
                        maxWidth='max-content'
                        size='xl'
                        weight='normal'
                        lineHeight='1.37'
                        margin="25px 0px"
                        sMargin="20px auto"
                    >
                        {data.heading}
                        <ImageContainer
                            width="210px"
                            sWidth="135px"
                            mWidth="135px"
                            margin="-20px 0px 0px 0px"
                            sMargin="-10px 0px 0px 0px"
                            xsMargin="-10px auto 0px auto"
                            sdisplay="block"
                        >
                            <Image
                                src={redUnderLine}
                            />
                        </ImageContainer>
                    </Heading>
                    <Text
                        color='MEDIUM_BLACK'
                        opacity='0.7'
                        size='xl'
                        weight='medium'
                        lineHeight='1.7'
                        letterSpacing='-0.33px'
                        width="max-content"
                        margin="0px 0px 40px"
                        sMargin="0px auto 25px"
                        padding="0px 5vw 0px 0px"
                        sPadding="0px"
                    >
                        {data.subHeading}
                    </Text>
                    <Text
                        color='MEDIUM_BLACK'
                        size='xl'
                        weight='medium'
                        lineHeight='2.1'
                        letterSpacing='-0.33px'
                        width="max-content"
                        margin="0px 0px 50px"
                        sMargin="0px auto"
                        padding="0px 5vw 0px 0px"
                        sPadding="0px"
                    >
                        {data.description}
                    </Text>
                    <MediaKitDocContainer>
                        {
                            data.docList.map((el, i) => {
                                return (
                                    <MediaKitDocWrapper key={i} onClick={el.pdf === "true" ? onButtonClick.bind(null, el.link): downloadImage.bind(null, el.link, title)}>
                                        <ImageContainer
                                            width="42px"
                                        >
                                            <Image src={el.imgSrc}/>
                                        </ImageContainer>
                                        <div>
                                            <Text
                                                size="xl"
                                                weight="large"
                                                margin="13px 0px 8px"
                                            >
                                                {el.heading}
                                            </Text>
                                            <Text
                                                size="md"
                                                color="LIGHTER_GREY"
                                                lineHeight= "1.25"
                                            >
                                                <Image
                                                    src={downloadIcon}
                                                    width="11px"
                                                    style={{ margin:"0px 8px 0px 0px" }}
                                                />
                                                {el.fileDescription}
                                            </Text>
                                        </div>
                                    </MediaKitDocWrapper>
                                )
                            })
                        }
                    </MediaKitDocContainer>
                </MediaKitTop>
                <MediaKitBody
                    backgroundAfter={hexagonNoFill}
                >
                    <Heading
                        color="MEDIUM_BLACK"
                        lineHeight="1.13"
                        letterSpacing="-0.5px"
                        margin="50px 0px 0px"
                        weight="normal"
                        sAlign="center"
                        size="sm"
                        opacity="0.7"
                    >
                        {data.galleryHeading}
                    </Heading>
                    {data.category && <GalleryFilter>
                        <Text
                            size="lg"
                            color="MEDIUM_BLACK"
                            weight="medium"
                            margin="33px 0px 45px"
                            sMargin="26px auto"
                        >
                            Category:&nbsp;&nbsp;
                            <CutomButton
                                minwidth="max-content"
                                width="199px"
                                backgroundcolor="WHITE"
                                color="CHQBOOK_BLUE"
                                texttransform="capitalize"
                                weight="medium"
                                size="lg"
                                border="2px solid #14467d78"
                                hovercolor="primary"
                                boxshadow="0 4px 8px 0 rgba(0, 0, 0, 0.05)"
                                hoverbackground="WHITE"
                                smargin="0 auto"
                                padding="2px 8px 1px"
                                letterspacing="0.2px"
                                borderradius="6px"
                                justifycontent="space-between"
                            >
                                Office Space &nbsp;
                                <Image src={downArrow} width="8px"/>
                            </CutomButton>
                        </Text>
                    </GalleryFilter>}
                    <Text
                        weight="large"
                        size="xl"
                        color="MEDIUM_GREY"
                        width="max-content"
                        margin="18px 0"
                        sMargin="18px auto 0px"
                    >
                        {data.galleryList.length} {data.typeOfDoc}
                    </Text>
                    <GalleryContainer>
                        {data.galleryList.map((el, i) => {
                            return (
                                <GalleryWrapper key={i} padding={el.padding}>
                                    <GalleryCard
                                        boxShadow="0 0 16px 0 rgba(0, 0, 0, 0.1)"
                                    >
                                        <ImageContainer
                                            flex="1"
                                            display="flex"
                                            flexDirection='column'
                                            jContent='center'
                                            backgroundColor='BACKGROUND_PLACEHOLDER'
                                        >
                                            <Image src={el.imgSrc} />
                                        </ImageContainer>
                                        <Text
                                            color="MEDIUM_BLACK"
                                            size="lg"
                                            weight="large"
                                            margin="12px 5px 12px"
                                            padding="0px 0px 13px 0px"
                                            sPadding="13px 0px"
                                            borderBottom="1px solid #d8d8d882"
                                        >
                                            {
                                                el.title
                                            }
                                        </Text>
                                        <DownloadWrapper>
                                            {
                                                el.dowloadList.map((ele,i)=>{
                                                    return (
                                                        <Text
                                                            borderRight="1px solid #d8d8d8"
                                                            padding="0px 13px 0px 0px"
                                                            lastPadding="0px 0px 0px 13px"
                                                            key={i}
                                                            onClick={downloadImage.bind(null, ele.downloadImage, el.title)}
                                                            cursor="pointer"
                                                        >
                                                            <Image
                                                                src={downloadIcon}
                                                                width="12px"
                                                                style={{ margin:"0px 8px 0px 0px" }}
                                                            />
                                                                {ele.text}
                                                        </Text>
                                                    )
                                                })
                                            }
                                        </DownloadWrapper>
                                    </GalleryCard>
                                </GalleryWrapper>
                            )
                        })}
                    </GalleryContainer>
                    <ReachUsContainer
                        backgroundImage={boxPattern}
                    >
                        <Text color="MEDIUM_BLACK" size="xxl">For media enquiries, please reach out to</Text>
                        <Text color="CHQBOOK_BLUE" size="xxl" weight="large">communications@chqbook.com</Text>
                    </ReachUsContainer>
                </MediaKitBody>
                </MediaKitContainer>
            </Layout>
        </Fragment>
    )
}

export default MediaKit;
