import React from 'react';
import MediaKit from '../../components/MediaKit'
import { graphql } from 'gatsby';
import { modifyContent } from '../../utils/helpers';

export const query = graphql`
        query{
            content: allGhostPage(
                filter: {
                    tags: { elemMatch: { name: { eq: "#media-kit"}}},
                }
            ) {
                nodes {
                    plaintext
                    meta_title
                    meta_description
                }
            }
        }
    `;

const MediaKitPage = props => {
    let data = modifyContent(props.data);
    const title = props.data.content?.nodes[0].meta_title;
    const description = props.data.content?.nodes[0].meta_description;

    return (
        <MediaKit
            data={data.content}
            title={title}
            description={description}
            location={props.location}
        />
    )
}

export default MediaKitPage;
